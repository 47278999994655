
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ClientList from "@/components/reusable/clients/ClientList.vue";

export default defineComponent({
  name: "finance-clients-list",
  components: {
    ClientList
  },
  data() {
    return {
      clients: []
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Users", ["Clients"]);
    });
  },
  created() {
    this.getClients();

    //Set page title
    document.title = "Clients List | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClients() {
      //Fetch underwriter dashboard data
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.FINANCE_CLIENTS_ROUTE)
          .then(({ data }) => {
            //Assign data to props
            this.clients = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    }
  }
});
