
import { defineComponent } from "vue";
import variables from "@/router/api";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default defineComponent({
  name: "client-table-listing",
  components: {},
  data() {
    return {
      avatarUrl: ""
    };
  },
  props: {
    clients: Array,
    route: String
  },
  created() {
    this.avatarUrl = variables.AVATAR_ROUTE;
    this.displayDatatable();
  },
  methods: {
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)"
          },
          processing: true
        });
      }, 3000);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    }
  }
});
